import { Component } from "react";
import Chart from "react-apexcharts";

class Barchart9 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          data: [89, 79, 79, 72],
          name: "total"
        }
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          fontFamily: "Lato, Helvetica, Arial, sans-serif"
        },
        fill: {
          colors: ["#2171b5"]
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return ((val / 118) * 100).toFixed(1) + "%";
          },
          style: {
            fontSize: "12px",
            colors: ["#FFFFFF"]
          }
        },
        title: {
          text: "Organizational Security Policies",
          align: "center"
        },
        xaxis: {
          categories: [
            [
              "My organization",
              "provides security",
              "training at regular ",
              "intervals"
            ],
            [
              "My organization",
              "has policies covering",
              "third-party package",
              "installation"
            ],
            [
              "My organization",
              "has warned me not",
              "to install untrusted",
              "software packages"
            ],
            [
              "My organization",
              "has a professional",
              "team responsible for",
              "reviewing usage of",
              "open-source software"
            ]
          ]
        }
      }
    };
  }

  render() {
    return (
      <div className="mixed-chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          width="100%"
          height="400px"
        />
      </div>
    );
  }
}

export default Barchart9;
