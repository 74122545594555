import { Component } from "react";
import Chart from "react-apexcharts";

class Columnchart8 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "total",
          data: [7, 15, 11, 28, 16, 21, 49]
        }
      ],
      options: {
        chart: {
          height: 350,
          type: "bar",
          fontFamily: "Lato, Helvetica, Arial, sans-serif"
        },
        plotOptions: {
          bar: {
            borderRadius: 0
          }
        },
        fill: {
          colors: ["#ef6548"]
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return ((val / 147) * 100).toFixed(1) + "%";
          },
          style: {
            fontSize: "12px",
            colors: ["#FFFFFF"]
          }
        },
        xaxis: {
          categories: [
            "1 - 3",
            "4 - 20",
            "21 - 49",
            "50 - 300",
            "301 - 999",
            "1,000 - 4,999",
            "5,000 or more"
          ],
          position: "bottom",
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5
              }
            }
          },
          tooltip: {
            enabled: true
          }
        },
        annotations: {
          xaxis: [
            {
              x: "50 - 300",
              x2: "5,000 or more",
              fillColor: "#fdd49e",
              label: {
                text: "More than 3/4 the sample"
              }
            }
          ]
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            }
          }
        },
        title: {
          text: "Headcount",
          floating: true,
          offsetY: 0,
          align: "center",
          style: {
            color: "#444"
          }
        }
      }
    };
  }

  render() {
    return (
      <div className="mixed-chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          width="100%"
          height="500px"
        />
      </div>
    );
  }
}

export default Columnchart8;
