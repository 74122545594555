import { Component } from "react";
import Chart from "react-apexcharts";

class Barchart1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          data: [
            131,
            50,
            47,
            34,
            28,
            21,
            18,
            17,
            11,
            7,
            5,
            5,
            4,
            3,
            2,
            2,
            2,
            1,
            1,
            1,
            1,
            1,
            1,
            1,
            1,
            1,
            1,
            1
          ],
          name: "total"
        }
      ],
      options: {
        chart: {
          type: "bar",
          height: 450,
          fontFamily: "Lato, Helvetica, Arial, sans-serif"
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true
          }
        },
        fill: {
          colors: ["#7fcdbb"]
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return ((val / 150) * 100).toFixed(1) + "%";
          },
          style: {
            fontSize: "12px",
            colors: ["#304758"]
          }
        },
        title: {
          text: "Programming Language(s)",
          align: "center"
        },
        xaxis: {
          categories: [
            "Python",
            "Shell",
            "JavaScript",
            "Java",
            "R",
            "C",
            "C++",
            "Go",
            "TypeScript",
            "Ruby",
            "PHP",
            "Julia",
            "Rust",
            "C#",
            "Lua",
            "Perl",
            "SQL",
            "Kotlin",
            "Swift",
            "Standard ML",
            "Haskell",
            "Clojure",
            "Lisp",
            "Scala",
            "Spark",
            "PowerShell",
            "Prolog",
            "Logtalk",
            "SAS",
            "Fortran"
          ]
        }
      }
    };
  }

  render() {
    return (
      <div className="mixed-chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          width="100%"
          height="600px"
        />
      </div>
    );
  }
}

export default Barchart1;
