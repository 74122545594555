import { Component } from "react";
import Chart from "react-apexcharts";

class Barchart6 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          data: [66, 53, 24, 18, 18, 16, 13, 10, 10, 9, 7, 6, 6, 5],
          name: "total"
        }
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          fontFamily: "Lato, Helvetica, Arial, sans-serif"
        },
        fill: {
          colors: ["#fec44f"]
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return ((val / 144) * 100).toFixed(1) + "%";
          },
          style: {
            fontSize: "12px",
            colors: ["#000000"]
          }
        },
        title: {
          text: "Role",
          align: "center"
        },
        xaxis: {
          categories: [
            ["Software engineer/", "developer"],
            ["Data scientist/", "data engineer/", "ML|AI researcher"],
            ["Manager, executive,", "or founder"],
            "DevOps engineer",
            ["Visualization designer/", "front end engineer/", "UI researcher"],
            ["Student (e.g.", "undergraduate/", "postgraduate)"],
            ["Educator (e.g. teacher", "lecturer/professor)"],
            "Scientist",
            "Business analyst",
            ["Pen tester,", "security engineer,", "cyber analyst"],
            ["QA/test engineer,", "build engineer"],
            ["Database admin."],
            "Other",
            ["Site reliability", "engineer"]
          ]
        }
      }
    };
  }

  render() {
    return (
      <div className="mixed-chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          width="100%"
          height="700px"
        />
      </div>
    );
  }
}

export default Barchart6;
