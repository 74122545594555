import { Component } from "react";
import Chart from "react-apexcharts";
// disregard minus signs below; they are an artifact of the visualization library used

class Barchart15 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "Strongly Disagree",
          data: [-2, -1, -3, -7, -6]
        },
        {
          name: "Disagree",
          data: [8, 7, 11, 20, 20]
        },
        {
          name: "Neutral",
          data: [26, 24, 25, 24, 15]
        },
        {
          name: "Agree",
          data: [69, 67, 79, 57, 39]
        },
        {
          name: "Strongly Agree",
          data: [32, 38, 18, 25, 53]
        }
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          fontFamily: "Lato, Helvetica, Arial, sans-serif"
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return Math.abs(val);
          }
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
          formatter: function (val) {
            return Math.abs(val);
          }
        },
        title: {
          text: "Attitudes Towards Code Reuse",
          align: "center"
        },
        xaxis: {
          categories: [
            [
              "I believe individual",
              "developers are",
              "responsible for",
              "keeping code safe"
            ],
            [
              "I wish I knew more",
              "about security",
              "vulnerabilities",
              "associated with code reuse"
            ],
            ["I trust the code I reuse"],
            [
              "I believe package",
              "registries are",
              "responsible for",
              "keeping code safe"
            ],
            [
              "I always check my",
              "code for credentials",
              "(API keys, tokens,",
              "etc.) before commits"
            ]
          ],
          labels: {
            show: false
          }
        },
        colors: ["#d7191c", "#fdae61", "#808080", "#a6d96a", "#1a9641"],
        tooltip: {
          y: {
            formatter: function (val) {
              return Math.abs((val / 140) * 100).toFixed(1) + "%";
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40
        }
      }
    };
  }

  render() {
    return (
      <div className="mixed-chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          width="100%"
          height="500px"
        />
      </div>
    );
  }
}

export default Barchart15;
