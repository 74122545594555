import { Component } from "react";
import Chart from "react-apexcharts";

class Freqchart3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "Annually (1x per year)",
          data: [10, 16, 17, 14, 19, 15, 14, 7, 12, 13]
        },
        {
          name: "Quarterly (≥4x per year)",
          data: [23, 25, 21, 12, 8, 8, 4, 6, 2, 4]
        },
        {
          name: "Monthly (≥12x per year)",
          data: [32, 19, 20, 4, 10, 7, 3, 5, 5, 0]
        },
        {
          name: "Weekly (≥52x per year)",
          data: [31, 10, 16, 15, 7, 4, 7, 3, 5, 2]
        },
        {
          name: "Semiweekly (≥100x per year)",
          data: [40, 19, 15, 9, 7, 8, 6, 10, 6, 6]
        }
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          fontFamily: "Lato, Helvetica, Arial, sans-serif"
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true
          }
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },
        title: {
          text: "Package Manager Usage Frequency",
          align: "center"
        },
        xaxis: {
          categories: [
            "pip",
            "conda",
            "npm",
            "Maven",
            "Yarn",
            "CRAN",
            "Gradle",
            "Other",
            "RubyGems",
            "NuGet"
          ],
          labels: {
            formatter: function (val) {
              return val;
            }
          }
        },
        colors: ["#9ebcda", "#8c96c6", "#8c6bb1", "#88419d", "#6e016b"],
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40
        }
      }
    };
  }

  render() {
    return (
      <div className="mixed-chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          width="100%"
          height="500px"
        />
      </div>
    );
  }
}

export default Freqchart3;
