import { Segment } from "semantic-ui-react";
import Freqchart10 from "../data/q10platforms";
import Barchart11 from "../data/q11installcriteria";
import Freqchart12 from "../data/q12pkgdiscov";
import Barchart14 from "../data/q14riskfactors";
import Freqchart13 from "../data/q13pkgvetting";
import Barchart15 from "../data/q15reuseattitudes";
import Barchart16 from "../data/q16securityattitudes";
import Barchart17 from "../data/q17tooldesiderata";
import Barchart18 from "../data/q18usabilitydesiderata";

function SelfReportedAttitudes() {
  return (
    <div
      style={{
        padding: "2em",
        backgroundColor: "#f5f5f5",
        counterReset: "section 9"
      }}
      start="10"
    >
      <Segment
        style={{
          marginTop: "5em"
        }}
        raised
      >
        <h1>Initial Survey Results</h1>
        <h2>Self-Reported Attitudes and Behaviors</h2>
        <h3 id="q10-version-control">
          How often do you download/install third-party code hosted on the
          following platforms?
        </h3>
        <Freqchart10 />
        <p className="SampleSize">
          <i>N</i>
          <sub>Q10</sub> = 145
        </p>
        <p id="related">
          <a href="#q12-package-discovery-info-sources">
            Q12: information sources for package vetting
          </a>{" "}
          and{" "}
          <a href="#q18-usability-desiderata">Q18: usability requirements</a>
        </p>
        <hr />
        <h3 id="q11-package-install-criteria">
          What criteria do you use to determine if a software package is safe to
          install?
        </h3>
        <Barchart11 />
        <p className="SampleSize">
          <i>N</i>
          <sub>Q11</sub> = 138
        </p>
        <p id="related">
          <a href="#q17-tool-desiderata">Q17: features of interest</a> and{" "}
          <a href="#q18-usability-desiderata">Q18: usability requirements</a>
        </p>
        <hr />
        <h3 id="q12-package-discovery-info-sources">
          How often do you use the following information sources to research
          packages?
        </h3>
        <Freqchart12 />
        <p className="SampleSize">
          <i>N</i>
          <sub>Q12</sub> = 143
        </p>
        <p id="related">
          <a href="#q17-tool-desiderata">Q17: features of interest</a> and{" "}
          <a href="#q18-usability-desiderata">Q18: usability requirements</a>
        </p>
        <hr />
        <h3 id="q13-package-vetting-info-sources">
          How often do you use the following information sources to evaluate
          packages?
        </h3>
        <Freqchart13 />
        <p className="SampleSize">
          <i>N</i>
          <sub>Q13</sub> = 143
        </p>
        <p id="related">
          <a href="#q17-tool-desiderata">Q17: features of interest</a> and{" "}
          <a href="#q18-usability-desiderata">Q18: usability requirements</a>
        </p>
        <hr />
        <h3 id="q14-package-vetting-risk-factors">
          What risk factors concern you when installing packages?
        </h3>
        <Barchart14 />
        <p className="SampleSize">
          <i>N</i>
          <sub>Q14</sub> = 143
        </p>
        <p id="related">
          <a href="#q17-tool-desiderata">Q17: features of interest</a> and{" "}
          <a href="#q18-usability-desiderata">Q18: usability requirements</a>
        </p>
        <hr />
        <h3 id="q15-reuse-attitudes">
          Rate your level of agreement with the statements below.
        </h3>
        <Barchart15 />
        <p className="SampleSize">
          <i>N</i>
          <sub>Q15</sub> = 140
        </p>
        <p id="related">
          <a href="#q17-tool-desiderata">Q17: features of interest</a> and{" "}
          <a href="#q18-usability-desiderata">Q18: usability requirements</a>
        </p>
        <hr />
        <h3 id="q16-security-attitudes">
          Rate your level of agreement with the statements below.
        </h3>
        <Barchart16 />
        <p className="SampleSize">
          <i>N</i>
          <sub>Q16</sub> = 140
        </p>
        <p id="related">
          <a href="#q17-tool-desiderata">Q17: features of interest</a> and{" "}
          <a href="#q18-usability-desiderata">Q18: usability requirements</a>
        </p>
        <hr />
        <h3 id="q17-tool-desiderata">
          How important are the following features in a security tool built to
          promote secure code reuse?
        </h3>
        <Barchart17 />
        <p className="SampleSize">
          <i>N</i>
          <sub>Q17</sub> = 136
        </p>
        <p id="related">
          <a href="#q15-reuse-attitudes">Q15: reuse attitudes</a> and{" "}
          <a href="#q16-security-attitudes">Q16: security attitudes</a>
        </p>
        <hr />
        <h3 id="q18-usability-desiderata">
          How important are the following usability elements in a security tool
          built to promote secure code reuse?
        </h3>
        <Barchart18 />
        <p className="SampleSize">
          <i>N</i>
          <sub>Q18</sub> = 136
        </p>
        <p id="related">
          <a href="#q15-reuse-attitudes">Q15: reuse attitudes</a> and{" "}
          <a href="#q16-security-attitudes">Q16: security attitudes</a>
        </p>
      </Segment>
    </div>
  );
}

export default SelfReportedAttitudes;
