import * as React from "react";
import { Switch, Route } from "react-router-dom";
import Background from "./pages/Background";
import PractitionerProfile from "./pages/Practitioner-Profile";
import SelfReportedAttitudes from "./pages/Self-Reported-Attitudes";

function Routes() {
  return (
    <div>
      <Switch>
        <Route path="/" exact>
          <Background />
        </Route>
        <Route path="/practitioner-profile">
          <PractitionerProfile />
        </Route>
        <Route path="/self-reported-attitudes-and-behaviors">
          <SelfReportedAttitudes />
        </Route>
      </Switch>
    </div>
  );
}

export default Routes;
