import { Segment } from "semantic-ui-react";
import Barchart1 from "../data/q01lang";
import Columnchart2 from "../data/q02yrscoding";
import Freqchart3 from "../data/q03wghtavg";
import Barchart4 from "../data/q04edattainment";
import Columnchart5 from "../data/q05yrscodingprof";
import Barchart6 from "../data/q06secbg";
import Barchart7 from "../data/q07role";
import Columnchart8 from "../data/q08orgheadcount";
import Barchart9 from "../data/q09secpol";

function PractitionerProfile() {
  return (
    <div
      style={{
        padding: "2em",
        backgroundColor: "#f5f5f5"
      }}
    >
      <Segment
        style={{
          marginTop: "5em"
        }}
        raised
      >
        <header className="page-header">
          <h1>Initial Survey Results</h1>
          <h2>Practitioner Profile</h2>
        </header>
        <h3 id="q1-programming-languages">
          Which programming language(s) do you use regularly?
        </h3>
        <Barchart1 />
        <p className="SampleSize">
          <i>N</i>
          <sub>Q1</sub> = 150
        </p>
        <p id="related">
          <a href="#q3-package-managers">Q3: package manager(s) used</a>
        </p>
        <hr />
        <h3 id="q2-years-coding-in-all-contexts">
          How many years ago did you first learn to code?
        </h3>
        <Columnchart2 />
        <p className="SampleSize">
          <i>N</i>
          <sub>Q2</sub> = 150
        </p>
        <p id="related">
          <a href="#q5-years-coding-professionally">
            Q5: years coding professionally
          </a>
        </p>
        <hr />
        <h3 id="q3-package-managers">
          How often do you use the following software package managers?
        </h3>
        <Freqchart3 />
        <p className="SampleSize">
          <i>N</i>
          <sub>Q3</sub> = 148
        </p>
        <p id="related">
          <a href="#q1-programming-languages">
            Q1: programming language(s) used
          </a>
        </p>
        <hr />
        <h3 id="q4-educational-attainment">
          What is the highest level of formal education you've attained?
        </h3>
        <Barchart4 />
        <p className="SampleSize">
          <i>N</i>
          <sub>Q4</sub> = 148
        </p>
        <p id="related">
          <a href="#q2-years-coding-in-all-contexts">Q2: total years coding</a>{" "}
          and{" "}
          <a href="#q5-years-coding-professionally">
            Q5: years coding professionally
          </a>
        </p>
        <hr />
        <h3 id="q5-years-coding-professionally">
          How many years have you been coding outside of coursework?
        </h3>
        <Columnchart5 />
        <p className="SampleSize">
          <i>N</i>
          <sub>Q5</sub> = 148
        </p>
        <p id="related">
          <a href="#q2-years-coding-in-all-contexts">Q2: total years coding</a>{" "}
          and{" "}
          <a href="#q5-years-coding-professionally">
            Q5: years coding professionally
          </a>
        </p>
        <hr />
        <h3 id="q6-security-background">
          Which of the statements below, if any, apply to you?
        </h3>
        <Barchart6 />
        <p className="SampleSize">
          <i>N</i>
          <sub>Q6</sub> = 147
        </p>
        <p id="related">
          <a href="#q5-years-coding-professionally">
            Q5: years coding professionally
          </a>
          , <a href="#q8-organization-headcount">Q8: organization headcount</a>,{" "}
          and{" "}
          <a href="#q9-organization-background">Q9: organization background</a>
        </p>
        <hr />
        <h3 id="q7-current-role">
          Which of the following best describes your current role?
        </h3>
        <Barchart7 />
        <p className="SampleSize">
          <i>N</i>
          <sub>Q7</sub> = 144
        </p>
        <p id="related">
          <a href="#q1-programming-languages">
            Q1: programming language(s) used
          </a>
        </p>
        <hr />
        <h3 id="q8-organization-headcount">
          How many full-time staff does your organization employ?
        </h3>
        <Columnchart8 />
        <p className="SampleSize">
          <i>N</i>
          <sub>Q8</sub> = 147
        </p>
        <p id="related">
          <a href="#q7-current-role">Q7: current role</a>
        </p>
        <hr />
        <h3 id="q9-organization-background">
          Which of the statements below, if any, apply to you?
        </h3>
        <Barchart9 />
        <p className="SampleSize">
          <i>N</i>
          <sub>Q9</sub> = 118
        </p>
        <p id="related">
          <a href="#q8-organization-headcount">Q8: organization headcount</a>
        </p>
        <hr />
      </Segment>
    </div>
  );
}

export default PractitionerProfile;
