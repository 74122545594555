import { Component } from "react";
import Chart from "react-apexcharts";
// disregard minus signs below; they are an artifact of the visualization library used

class Freqchart10 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "Annually (1x per year)",
          data: [-10, -16, -20, -16, -12]
        },
        {
          name: "Quarterly (≥4x per year)",
          data: [31, 20, 8, 10, 1]
        },
        {
          name: "Monthly (≥12x per year)",
          data: [41, 16, 6, 4, 7]
        },
        {
          name: "Weekly (≥52x per year)",
          data: [22, 8, 3, 1, 2]
        },
        {
          name: "Semiweekly (≥100x per year)",
          data: [36, 8, 3, 1, 3]
        }
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          fontFamily: "Lato, Helvetica, Arial, sans-serif"
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return Math.abs(val);
          }
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
          formatter: function (val) {
            return Math.abs(val);
          }
        },
        title: {
          text: "Distributed Version Control System Usage Frequency",
          align: "center"
        },
        xaxis: {
          categories: ["GitHub", "GitLab", "Bitbucket", "SourceForge", "Other"],
          labels: {
            show: false
          }
        },
        colors: ["#9ebcda", "#8c96c6", "#8c6bb1", "#88419d", "#6e016b"],
        tooltip: {
          y: {
            formatter: function (val) {
              return Math.abs((val / 145) * 100).toFixed(1) + "%";
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40
        }
      }
    };
  }

  render() {
    return (
      <div className="mixed-chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          width="100%"
          height="500px"
        />
      </div>
    );
  }
}

export default Freqchart10;
