import { Component } from "react";
import Chart from "react-apexcharts";
// disregard minus signs below; they are an artifact of the visualization library used

class Barchart17 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "Indifferent",
          data: [-10, -14, -14, -17, -15, -20, -32, -51]
        },
        {
          name: "Not Important (No Need)",
          data: [2, 14, 5, 21, 22, 45, 9, 10]
        },
        {
          name: "Important (Nice to Have)",
          data: [29, 51, 45, 74, 60, 51, 48, 40]
        },
        {
          name: "Very Important (Must Have)",
          data: [94, 56, 70, 22, 36, 18, 46, 34]
        }
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          fontFamily: "Lato, Helvetica, Arial, sans-serif"
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return Math.abs(val);
          }
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
          formatter: function (val) {
            return Math.abs(val);
          }
        },
        title: {
          text: "Attitudes Towards Security",
          align: "center"
        },
        xaxis: {
          categories: [
            "Malware checks",
            "License info.",
            ["Suspicious", "component", "detection"],
            ["Autosuggested", "package", "alternative(s)"],
            ["Contributor", "contacts"],
            ["Contributor", "location info."],
            "Namespace checks",
            ["Software bill", "of materials", "(SBOM)"]
          ],
          labels: {
            show: false
          }
        },
        colors: ["#808080", "#fdae61", "#a6d96a", "#1a9641"],
        tooltip: {
          y: {
            formatter: function (val) {
              return Math.abs((val / 136) * 100).toFixed(1) + "%";
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40
        }
      }
    };
  }

  render() {
    return (
      <div className="mixed-chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          width="100%"
          height="700px"
        />
      </div>
    );
  }
}

export default Barchart17;
