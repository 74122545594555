import { Component } from "react";
import Chart from "react-apexcharts";

class Barchart11 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          data: [123, 92, 88, 66, 54, 38, 28, 7],
          name: "total"
        }
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          fontFamily: "Lato, Helvetica, Arial, sans-serif"
        },
        fill: {
          colors: ["#2171b5"]
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return ((val / 138) * 100).toFixed(1) + "%";
          },
          style: {
            fontSize: "12px",
            colors: ["#FFFFFF"]
          }
        },
        xaxis: {
          categories: [
            [
              "Package appears",
              "popular/widely used",
              "(e.g. 1,000+ GitHub",
              "stars or 10,000+",
              "monthly downloads)"
            ],
            [
              "Maintainer activity",
              "seems recent (e.g.",
              "changelog updates/",
              "commits in past",
              "90 days)"
            ],
            [
              "Source code and/",
              "or code binary",
              "available to inspect",
              "without installing"
            ],
            ["Dependencies", "clearly listed in an", "inventory or manifest"],
            [
              "No package security",
              "advisories/common",
              "vulnerabilities and",
              "exposures (CVEs)"
            ],
            [
              "CI/code coverage",
              "badges indicate",
              "package is passing",
              "at least some tests"
            ],
            [
              "Commiter(s) clearly",
              "identified by location (e.g.",
              "jsmith in Cambridge, UK)"
            ],
            "None of the above"
          ]
        },
        title: {
          text: "Package Installation Criteria",
          align: "center"
        }
      }
    };
  }

  render() {
    return (
      <div className="mixed-chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          width="100%"
          height="700px"
        />
      </div>
    );
  }
}

export default Barchart11;
