import { Component } from "react";
import Chart from "react-apexcharts";

class Barchart14 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          data: [104, 77, 76, 69, 67, 17],
          name: "total"
        }
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          fontFamily: "Lato, Helvetica, Arial, sans-serif"
        },
        fill: {
          colors: ["#2171b5"]
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return ((val / 143) * 100).toFixed(1) + "%";
          },
          style: {
            fontSize: "12px",
            colors: ["#FFFFFF"]
          }
        },
        title: {
          text: "Key Risk Factors",
          align: "center"
        },
        xaxis: {
          categories: [
            [
              "Malware --",
              "malicious code within",
              "the package you",
              "plan to install"
            ],
            [
              "Undisclosed dependencies --",
              "package you plan to",
              "install depends on",
              "other potentially",
              "compromised packages"
            ],
            [
              "Credential theft --",
              "package you plan to",
              "install capable of",
              "extracting developer",
              "credentials"
            ],
            [
              "Package hijacking --",
              "malicious takeover of",
              "maintainer accounts on",
              "package you plan",
              "to install"
            ],
            [
              "Typosquatting --",
              "malicious code",
              "within a package",
              "that has a similar",
              "name to the package",
              "you plan to install"
            ],
            "None of the above"
          ]
        }
      }
    };
  }

  render() {
    return (
      <div className="mixed-chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          width="100%"
          height="700px"
        />
      </div>
    );
  }
}

export default Barchart14;
