import { Segment } from "semantic-ui-react";
import { InlineIcon } from "@iconify/react";
import pythonIcon from "@iconify-icons/logos/python";
import gitIcon from "@iconify-icons/logos/git";
import javascriptIcon from "@iconify-icons/logos/javascript";
import javaIcon from "@iconify-icons/logos/java";
import rLang from "@iconify-icons/logos/r-lang";
import goIcon from "@iconify-icons/logos/go";
import cPlusplus from "@iconify-icons/logos/c-plusplus";
import cSharp from "@iconify-icons/logos/c-sharp";
import fileTypeRust from "@iconify-icons/vscode-icons/file-type-rust";
import fileTypePhp from "@iconify-icons/vscode-icons/file-type-php";
import fileTypeDocker from "@iconify-icons/vscode-icons/file-type-docker";

function Background() {
  return (
    <div
      style={{
        padding: "2em",
        backgroundColor: "#f5f5f5"
      }}
    >
      <Segment
        style={{
          marginTop: "5em"
        }}
        raised
      >
        <h1>IQT Labs Secure Code Reuse Survey</h1>
        <h2>Survey Background</h2>
        <p>
          Open source software communities rely heavily on user trust. However,
          typosquatting, watering hole attacks, and developer infrastructure
          exploits can easily undermine the same honor system that enables easy
          software package reuse. To better understand trust-based code reuse
          within language-based ecosystems like npm and pip, IQT Labs recently
          surveyed 150 software engineers, data scientists, and web developers.
        </p>
        <p className="logoblock">
          <InlineIcon icon={gitIcon} height="60" />{" "}
          <InlineIcon icon={pythonIcon} height="60" />{" "}
          <InlineIcon icon={javascriptIcon} height="60" />{" "}
          <InlineIcon icon={javaIcon} height="60" />{" "}
          <InlineIcon icon={rLang} height="60" />{" "}
          <InlineIcon icon={goIcon} height="60" />{" "}
          <InlineIcon icon={cPlusplus} height="60" />{" "}
          <InlineIcon icon={cSharp} height="60" />{" "}
          <InlineIcon icon={fileTypeRust} height="60" />{" "}
          <InlineIcon icon={fileTypePhp} height="60" />{" "}
          <InlineIcon icon={fileTypeDocker} height="60" />{" "}
        </p>
      </Segment>
    </div>
  );
}

export default Background;
