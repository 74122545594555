import { InlineIcon } from "@iconify/react";
import { Container, Menu } from "semantic-ui-react";
import terminalSolid from "@iconify-icons/teenyicons/terminal-solid";
import { NavLink } from "react-router-dom";

function Header() {
  return (
    <div>
      <Menu borderless stackable size="massive" fixed="top">
        <Container>
          <Menu.Item header>
            <NavLink to="/" exact activeClassName="is-active">
              <InlineIcon
                icon={terminalSolid}
                height="40"
                style={{ paddingRight: 10 }}
              />{" "}
            </NavLink>
            <NavLink to="/" exact activeClassName="is-active">
              IQT Labs Secure Code Reuse Survey
            </NavLink>
          </Menu.Item>
          <Menu.Item>
            <NavLink to="/practitioner-profile" activeClassName="is-active">
              Who took the survey?
            </NavLink>
          </Menu.Item>
          <Menu.Item>
            <NavLink
              to="/self-reported-attitudes-and-behaviors"
              activeClassName="is-active"
            >
              What did they report?
            </NavLink>
          </Menu.Item>
          <Menu.Item>
            <a
              href="https://www.surveymonkey.com/r/codereuse"
              rel="noreferrer"
              target="_blank"
            >
              Take the survey yourself!
            </a>
          </Menu.Item>
        </Container>
      </Menu>
    </div>
  );
}

export default Header;
