import { Component } from "react";
import Chart from "react-apexcharts";
// disregard minus signs below; they are an artifact of the visualization library used

class Freqchart13 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "Never (0% of the time)",
          data: [-5, -23, -22, -54, -31]
        },
        {
          name: "Seldom (≈25% of the time)",
          data: [11, 41, 28, 19, 6]
        },
        {
          name: "Often (≈50% of the time)",
          data: [24, 34, 22, 14, 5]
        },
        {
          name: "Regularly (≈75% of the time)",
          data: [39, 17, 27, 13, 1]
        },
        {
          name: "Always (≈100% of the time)",
          data: [57, 9, 18, 12, 7]
        }
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          fontFamily: "Lato, Helvetica, Arial, sans-serif"
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return Math.abs(val);
          }
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
          formatter: function (val) {
            return Math.abs(val);
          }
        },
        title: {
          text: "Approaches to Package Vetting",
          align: "center"
        },
        xaxis: {
          categories: [
            "README/project docs",
            "Project Issues or bug reports",
            "Source code inspection",
            "Pre-install package scan",
            "Other"
          ],
          labels: {
            show: false
          }
        },
        colors: ["#9ebcda", "#8c96c6", "#8c6bb1", "#88419d", "#6e016b"],
        tooltip: {
          y: {
            formatter: function (val) {
              return Math.abs((val / 145) * 100).toFixed(1) + "%";
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40
        }
      }
    };
  }

  render() {
    return (
      <div className="mixed-chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          width="100%"
          height="500px"
        />
      </div>
    );
  }
}

export default Freqchart13;
