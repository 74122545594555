import { Component } from "react";
import Chart from "react-apexcharts";

class Barchart4 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          data: [31, 2, 58, 52, 1, 2, 1, 1],
          name: "total"
        }
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          fontFamily: "Lato, Helvetica, Arial, sans-serif"
        },
        fill: {
          colors: ["#3f007d"]
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return ((val / 148) * 100).toFixed(1) + "%";
          },
          style: {
            fontSize: "12px",
            colors: ["#FFFFFF"]
          }
        },
        title: {
          text: "Educational Attainment",
          align: "center"
        },
        xaxis: {
          categories: [
            "Doctorate",
            ["Professional degree", "(JD, MPP, MBA)"],
            "Master’s degree",
            "Bachelor’s degree",
            "Associate's degree",
            "Some college credit",
            "High school diploma",
            "Some high school"
          ]
        }
      }
    };
  }

  render() {
    return (
      <div className="mixed-chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          width="100%"
          height="400px"
        />
      </div>
    );
  }
}

export default Barchart4;
