import { Component } from "react";
import Chart from "react-apexcharts";

class Barchart6 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          data: [63, 54, 40, 21],
          name: "total"
        }
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          fontFamily: "Lato, Helvetica, Arial, sans-serif"
        },
        fill: {
          colors: ["#ef3b2c"]
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return ((val / 147) * 100).toFixed(1) + "%";
          },
          style: {
            fontSize: "12px",
            colors: ["#FFFFFF"]
          }
        },
        title: {
          text: "Security Background",
          align: "center"
        },
        xaxis: {
          categories: [
            ["I consider myself", "a security novice"],
            "I read security blogs",
            ["I am a member of", "a security group"],
            "None of the above"
          ]
        }
      }
    };
  }

  render() {
    return (
      <div className="mixed-chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          width="100%"
          height="400px"
        />
      </div>
    );
  }
}

export default Barchart6;
