import { Component } from "react";
import Chart from "react-apexcharts";
// disregard minus signs below; they are an artifact of the visualization library used

class Barchart16 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "Strongly Disagree",
          data: [-10, -19, -14, -14, -19]
        },
        {
          name: "Disagree",
          data: [41, 44, 52, 57, 60]
        },
        {
          name: "Neutral",
          data: [31, 26, 35, 24, 12]
        },
        {
          name: "Agree",
          data: [27, 36, 24, 26, 25]
        },
        {
          name: "Strongly Agree",
          data: [11, 11, 9, 8, 7]
        }
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          fontFamily: "Lato, Helvetica, Arial, sans-serif"
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return Math.abs(val);
          }
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
          formatter: function (val) {
            return Math.abs(val);
          }
        },
        title: {
          text: "Attitudes Towards Security",
          align: "center"
        },
        xaxis: {
          categories: [
            ["I do not engage", "in pre-install", "code vetting"],
            [
              "I only upgrade my",
              "software dependencies",
              "if they contain a",
              "critical vulnerability"
            ],
            [
              "I feel comfortable",
              "evaluating a new",
              "library for security risks"
            ],
            [
              "I have adequate",
              "tools to perform",
              "pre-install security",
              "checks"
            ],
            [
              "In the past, I",
              "have encountered",
              "security issues",
              "from code I downloaded"
            ]
          ],
          labels: {
            show: false
          }
        },
        colors: ["#d7191c", "#fdae61", "#808080", "#a6d96a", "#1a9641"],
        tooltip: {
          y: {
            formatter: function (val) {
              return Math.abs((val / 140) * 100).toFixed(1) + "%";
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40
        }
      }
    };
  }

  render() {
    return (
      <div className="mixed-chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          width="100%"
          height="500px"
        />
      </div>
    );
  }
}

export default Barchart16;
