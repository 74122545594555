import { Component } from "react";
import Chart from "react-apexcharts";

class Columnchart2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "total",
          data: [
            3,
            3,
            11,
            4,
            13,
            14,
            12,
            4,
            3,
            18,
            6,
            3,
            3,
            9,
            11,
            1,
            2,
            1,
            9,
            1,
            1,
            2,
            16
          ]
        }
      ],
      options: {
        chart: {
          height: 350,
          type: "bar",
          fontFamily: "Lato, Helvetica, Arial, sans-serif"
        },
        plotOptions: {
          bar: {
            borderRadius: 0
          }
        },
        fill: {
          colors: ["#41b6c4"]
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return ((val / 150) * 100).toFixed(1) + "%";
          },
          style: {
            fontSize: "12px",
            colors: ["#304758"]
          }
        },
        xaxis: {
          categories: [
            "1Y",
            "2Y",
            "3Y",
            "4Y",
            "5Y",
            "6Y",
            "7Y",
            "8Y",
            "9Y",
            "10Y",
            "11Y",
            "12Y",
            "13Y",
            "14Y",
            "15Y",
            "16Y",
            "17Y",
            "18Y",
            "20Y",
            "21Y",
            "24Y",
            "25Y",
            "25Y+"
          ],
          position: "bottom",
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5
              }
            }
          },
          tooltip: {
            enabled: true
          }
        },
        annotations: {
          xaxis: [
            {
              x: "1Y",
              x2: "10Y",
              fillColor: "#ffffd9",
              label: {
                text: "More than 1/2 the sample"
              }
            }
          ]
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            }
          }
        },
        title: {
          text: "Overall Years of Experience",
          align: "center",
          style: {
            color: "#444"
          }
        }
      }
    };
  }

  render() {
    return (
      <div className="mixed-chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          width="100%"
          height="500px"
        />
      </div>
    );
  }
}

export default Columnchart2;
