import { Component } from "react";
import Chart from "react-apexcharts";
// disregard minus signs below; they are an artifact of the visualization library used

class Freqchart12 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "Never (0% of the time)",
          data: [-4, -11, -8, -29, -26]
        },
        {
          name: "Seldom (≈25% of the time)",
          data: [16, 24, 24, 22, 8]
        },
        {
          name: "Often (≈50% of the time)",
          data: [24, 35, 39, 21, 8]
        },
        {
          name: "Regularly (≈75% of the time)",
          data: [45, 31, 32, 20, 6]
        },
        {
          name: "Always (≈100% of the time)",
          data: [50, 27, 16, 17, 5]
        }
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          fontFamily: "Lato, Helvetica, Arial, sans-serif"
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return Math.abs(val);
          }
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
          formatter: function (val) {
            return Math.abs(val);
          }
        },
        title: {
          text: "Approaches to Package Discovery",
          align: "center"
        },
        xaxis: {
          categories: [
            "Package manager search",
            "Stack Overflow",
            "Google/Web search",
            "Trusted peer",
            "Other"
          ],
          labels: {
            show: false
          }
        },
        colors: ["#9ebcda", "#8c96c6", "#8c6bb1", "#88419d", "#6e016b"],
        tooltip: {
          y: {
            formatter: function (val) {
              return Math.abs((val / 145) * 100).toFixed(1) + "%";
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40
        }
      }
    };
  }

  render() {
    return (
      <div className="mixed-chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          width="100%"
          height="500px"
        />
      </div>
    );
  }
}

export default Freqchart12;
