import * as React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./Header";
import Routes from "./Routes";
import Footer from "./Footer";
import "./styles.css";

export default function SurveyContent() {
  return (
    <div className="main">
      <Router>
        <Header />
        <Routes />
      </Router>
      <Footer />
    </div>
  );
}
